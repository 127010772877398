import { ArrowLeftOutlined, ArrowRightOutlined, FullscreenExitOutlined, FullscreenOutlined, MenuOutlined, StopOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, Layout, message, Modal, Row, Switch, Tour, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Draggable from "react-draggable";

import PrepMenuTopics from "../../components/PrepMenuTopics";
import { SendFeedback } from "../../api";

const { Content, Header } = Layout;

const StudentPrep = () => {
  const [form] = Form.useForm();
  const [formFeedback] = Form.useForm();

  const { mutate: sendFeedback, isLoading: isSendingFeedback } = SendFeedback();

  const navigate = useNavigate();
  const { prepType, questionId } = useParams();

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const prepQuestions = JSON.parse(sessionStorage.getItem("prepQuestions"));
  const currentQuestionIndex = prepQuestions?.findIndex((el) => el.id === +questionId);

  const [showAnswer, setShowAnswer] = useState(false);
  const [radFileUrl, setRadFileUrl] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showModal, setShowModal] = useState(() => {
    return !sessionStorage.getItem("prepModalShown");
  });
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [navIsVisible, setNavIsVisible] = useState(false);
  const [showKeyPointsModal, setShowKeyPointsModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = useRef(null);

  const currentQuestion = prepQuestions?.find((el) => el.id === +questionId);

  console.log(currentQuestion);

  const closeSidenav = () => {
    setNavIsVisible(false);
  };

  const openSidenav = () => {
    setNavIsVisible(true);
  };

  const onEndPreparationClick = () => {
    sessionStorage.removeItem("prepQuestions");
    navigate("/medical-student");
  };

  useEffect(() => {
    setShowAnswer(false);
    setShowHistory(false);
    form.resetFields();
    setRadFileUrl("");

    if (currentQuestion) {
      form.setFieldsValue({
        primary_diagnosis: currentQuestion.primary_diagnosis,
        secondary_diagnosis: currentQuestion.secondary_diagnosis,
        history: "",
      });
      setRadFileUrl(currentQuestion.json_url);
    }
  }, [questionId]);

  useEffect(() => {
    if (showModal) {
      sessionStorage.setItem("prepModalShown", "true");
    }
  }, [showModal]);

  const exitHandler = () => {
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      // closeFullscreen();
      setIsFullScreen(false);
    }
  };

  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  const onShowAnswer = () => {
    if (form.getFieldValue("password") === "radmenow") {
      setShowAnswer(true);
    } else {
      message.error("Incorrect password");
    }
  };

  const onPreviousClick = () => {
    navigate(`/student-prep/${prepType}/${prepQuestions[currentQuestionIndex - 1].id}`);
  };

  const onNextClick = () => {
    navigate(`/student-prep/${prepType}/${prepQuestions[currentQuestionIndex + 1].id}`);
  };

  const handleFullSCreen = () => {
    if (isFullScreen) {
      closeFullscreen();
      setIsFullScreen(false);
    } else {
      openFullScreen();
      setIsFullScreen(true);
    }
  };

  const openFullScreen = () => {
    let elem = document.getElementById("rou");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  const handleDoubleClick = () => {
    handleFullSCreen();
  };

  const handleSubmitFeedback = (values) => {
    if (!currentQuestion?.id) message.error("Something went wrong");

    const body = {
      id: currentQuestion?.case_num,
      message: values.feedback,
    };

    if (values.email) {
      body.email = values.email;
    }

    sendFeedback(body, {
      onSuccess: () => {
        message.success("Feedback sent successfully");
        setShowFeedbackModal(false);
      },
    });
  };

  const handleCancel = () => {
    setShowFeedbackModal(false);
    formFeedback.resetFields();
  };

  const handleHistoryToggle = (checked) => {
    setShowHistory(checked);
    form.setFieldsValue({
      history: checked ? currentQuestion?.history : "",
    });
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const steps = [
    {
      title: "Introduction",
      description: "This module contains multiple cases; each case has a primary diagnosis and often several secondary diagnoses.",
      target: () => ref1.current,
      placement: "left",
    },
    {
      title: "History",
      description:
        "The history is withheld by default. First try to only use the images to come up with the primary diagnosis. If that is too difficult, come up with a differential diagnosis, then use the history to determine the primary diagnosis!",
      target: () => ref2.current,
      placement: "bottomRight",
    },
    {
      title: "Your diagnosis",
      description: "Write your primary diagnosis/differential diagnosis here along with as many incidental secondary diagnoses you can find.",
      target: () => ref3.current,
      placement: "leftTop",
    },
    {
      title: "Show Answer",
      description: (
        <ul>
          <li>
            After you have reviewed the case and typed your diagnoses, ask your supervising physician to enter the password. The primary and secondary diagnoses will be revealed—how did you do!?
          </li>
          <li>Your supervising physician should be available for an in-depth discussion if you have questions or need clarification.</li>
          <li>When you are ready, use the “next” button below to move on to the next case.</li>
        </ul>
      ),
      target: () => ref4.current,
      placement: "leftTop",
    },
    {
      title: "Menu",
      description: "Use the menu button to skip to a different case or to resume viewing a case you didn’t finish diagnosing.",
      target: () => ref5.current,
      placement: "rightTop",
    },
    {
      title: "Feedback",
      description: (
        <ul>
          <li>We welcome feedback on this case (perhaps we missed an incidental diagnosis, or you disagree with 1 or more of our diagnoses, or you simply wish to leave a compliment)</li>
          <li>Feedback is anonymous unless you provide your email, in which case, we will reply in 3-5 business days.</li>
        </ul>
      ),
      target: () => ref6.current,
      placement: "rightTop",
    },
  ];

  return (
    <>
      <Layout>
        <Drawer title="RadMe" placement="left" onClose={closeSidenav} open={navIsVisible}>
          <PrepMenuTopics onClick={closeSidenav} />
        </Drawer>
        <Header className="header-style">
          <Button ref={ref5} className="menu" type="primary" icon={<MenuOutlined />} onClick={openSidenav} />

          <Button type="primary" size="large" icon={<StopOutlined />} onClick={onEndPreparationClick}>
            End Preparation
          </Button>
        </Header>
        <Content
          style={{
            height: "calc(100vh - 64px)",
            padding: "12px 12px 0 12px",
            background: "#000",
            color: "#E3E3E7",
            display: "flex",
            flexDirection: "column",
          }}>
          <Row gutter={[16, 16]} style={{ height: "calc(100% - 50px)" }}>
            <Col xs={24} md={18} style={{ height: "100%" }}>
              <div
                id="rou"
                onDoubleClick={handleDoubleClick}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {isFullScreen ? (
                    <FullscreenExitOutlined onClick={handleFullSCreen} style={{ fontSize: "2em", padding: "1em 1em 0 1em" }} />
                  ) : (
                    <FullscreenOutlined onClick={handleFullSCreen} style={{ fontSize: "2em", padding: "0 1em 0 1em" }} />
                  )}
                </div>

                <embed
                  src={radFileUrl}
                  style={{
                    width: "100%",
                    flex: 1,
                    height: `${isFullScreen ? "100%" : "90%"}`,
                  }}
                />
              </div>
            </Col>
            <Col ref={ref1} xs={24} md={6}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                  <Typography.Title level={2} style={{ color: "#FEFE", marginTop: 0 }}>
                    Question: {currentQuestionIndex + 1}
                  </Typography.Title>
                  <Typography.Text style={{ color: "#FEFE" }}>Case ID: {currentQuestion?.case_num} </Typography.Text>
                </div>
                <Button ref={ref6} type="link" size="large" icon={<ExclamationCircleOutlined />} style={{ color: "#FEFE" }} onClick={() => setShowFeedbackModal(true)}>
                  Feedback
                </Button>
              </div>
              <Form form={form} layout="vertical">
                <div style={{ display: "flex", justifyContent: "space-between", gap: "6px" }}>
                  <label style={{ color: "#CBC", fontSize: "20px" }} htmlFor="history">
                    History
                  </label>
                  <Switch
                    ref={ref2}
                    checked={showHistory}
                    onChange={handleHistoryToggle}
                    style={{ background: showHistory ? "#d23f57" : "gray" }}
                    checkedChildren="Hide history"
                    unCheckedChildren="Show history"
                  />
                </div>
                <Form.Item name="history">
                  <Input.TextArea readOnly rows={2} autoSize={{ minRows: 2, maxRows: 6 }} style={{ minWidth: "100%" }} />
                </Form.Item>
                <label style={{ color: "#CBC", fontSize: "20px" }} htmlFor="your_diagnosis" ref={ref3}>
                  Your Diagnosis
                </label>
                <Form.Item name="your_diagnosis">
                  <Input.TextArea rows={2} autoSize={{ minRows: 2, maxRows: 6 }} style={{ minWidth: "100%" }} />
                </Form.Item>
                {showAnswer ? (
                  <>
                    <label style={{ color: "#CBC", fontSize: "20px" }} htmlFor="primary_diagnosis">
                      Primary Diagnosis
                    </label>
                    <Form.Item name="primary_diagnosis">
                      <Input.TextArea readOnly rows={2} autoSize={{ minRows: 2, maxRows: 6 }} style={{ minWidth: "100%" }} />
                    </Form.Item>

                    {form.getFieldValue("secondary_diagnosis") && (
                      <>
                        <label style={{ color: "#CBC", fontSize: "20px" }} htmlFor="secondary_diagnosis">
                          Secondary Diagnosis
                        </label>
                        <Form.Item name="secondary_diagnosis">
                          <Input.TextArea readOnly rows={2} autoSize={{ minRows: 2, maxRows: 6 }} style={{ minWidth: "100%" }} />
                        </Form.Item>
                      </>
                    )}
                    {currentQuestion.key_points && (
                      <Button type="primary" onClick={() => setShowKeyPointsModal(true)}>
                        Key Points
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Form.Item name="password">
                      <input
                        type="password"
                        placeholder="Enter password to view answer"
                        onKeyDown={(e) => e.key === "Enter" && onShowAnswer()}
                        onChange={(e) => form.setFieldsValue({ password: e.target.value })}
                        autoComplete="off"
                        style={{
                          width: "100%",
                          padding: "8px 12px",
                          border: "1px solid #D23F57",
                          borderRadius: "6px",
                          backgroundColor: "#fff",
                          color: "#000",
                          fontSize: "14px",
                          outline: "none",
                        }}
                      />
                    </Form.Item>
                    <Button type="primary" onClick={onShowAnswer} ref={ref4}>
                      Show Answer
                    </Button>
                  </>
                )}
              </Form>
            </Col>
          </Row>
          <Row
            justify="space-between"
            style={{
              padding: "12px",
              backgroundColor: "transparent",
            }}>
            <Button size="large" type="primary" icon={<ArrowLeftOutlined />} disabled={currentQuestionIndex === 0} onClick={onPreviousClick}>
              Previous
            </Button>
            <Button size="large" type="primary" disabled={currentQuestionIndex === prepQuestions.length - 1} icon={<ArrowRightOutlined />} onClick={onNextClick}>
              Next
            </Button>
          </Row>

          <Tour open={showModal} onClose={() => setShowModal(false)} steps={steps} />
        </Content>
        <Modal title={`Give feedback for case ${currentQuestion?.case_num}`} open={showFeedbackModal} onCancel={handleCancel} footer={null}>
          <Form form={formFeedback} layout="vertical" onFinish={handleSubmitFeedback}>
            <Form.Item name="feedback" label="Feedback" rules={[{ required: true, message: "Please enter your feedback" }]}>
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item name="email">
              <Input placeholder="Include your email if a reply is desired otherwise leave blank for anonymity" />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="primary" htmlType="submit" loading={isSendingFeedback}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal>

        {/* Key points modal */}
        <Modal
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              onFocus={() => {}}
              onBlur={() => {}}>
              Key Points
            </div>
          }
          footer={null}
          open={showKeyPointsModal}
          onCancel={() => setShowKeyPointsModal(false)}
          modalRender={(modal) => (
            <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}>
          <div style={{ whiteSpace: "pre-line" }}>{currentQuestion.key_points}</div>
        </Modal>
      </Layout>
      <footer className="footer-style">
        <span>Copyright © {new Date().getFullYear()} RadMe LLC. All rights reserved.</span>
      </footer>
    </>
  );
};

export default StudentPrep;
