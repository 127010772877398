import { Card, Col, Row, Typography } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const { Title } = Typography;

const HomePage = () => {
  useEffect(() => {
    window.location.href = "/medical-student";
  }, []);

  return (
    <Row gutter={[16, 16]} className="mt-1">
      <Col xs={24}>
        <Title level={2}>RadMe - Virtual Reading Station for Learning Radiology</Title>
      </Col>
      <Col xs={24} md={12}>
        <Link to="/medical-student">
          <Card hoverable className="center-text" style={{ backgroundColor: "#CCD4DC" }}>
            <Title level={3}>Medical Student</Title>
          </Card>
        </Link>
      </Col>
      <Col xs={24} md={12}>
        <Link to="/resident">
          <Card hoverable className="center-text" style={{ backgroundColor: "#d23f57", cursor: "pointer" }}>
            <Title style={{ color: "#fff" }} level={3}>
              Resident
            </Title>
          </Card>
        </Link>
      </Col>
    </Row>
  );
};

export default HomePage;
