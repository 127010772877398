import { useState } from "react";
import { Button, Drawer, Layout } from "antd";
import { MenuOutlined, StopOutlined } from "@ant-design/icons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import TutorialMenuTopics from "../components/TutorialMenuTopics";

const { Content, Header } = Layout;

const TutorialLayout = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [navIsVisible, setNavIsVisible] = useState(false);

  const closeSidenav = () => {
    setNavIsVisible(false);
  };

  const openSidenav = () => {
    setNavIsVisible(true);
  };

  return (
    <Layout>
      <Drawer title="RadMe" placement="left" onClose={closeSidenav} open={navIsVisible}>
        <TutorialMenuTopics onClick={closeSidenav} />
      </Drawer>
      <Header className="header-style">
        <Button className="menu" type="primary" icon={<MenuOutlined />} onClick={openSidenav} />
        {pathname !== "/tutorial/review" ? (
          <Button type="primary" size="large" icon={<StopOutlined />} onClick={() => navigate("/tutorial/review")}>
            End Exam
          </Button>
        ) : null}
      </Header>
      <Content style={{ minHeight: "92vh", padding: "12px", background: "#000", color: "#E3E3E7" }}>{children ? children : <Outlet />}</Content>
      <footer className="footer-style">
        <span>Copyright © {new Date().getFullYear()} RadMe LLC. All rights reserved.</span>
      </footer>
    </Layout>
  );
};

export default TutorialLayout;
